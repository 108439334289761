import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import parse from "html-react-parser";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import Hero from "../components/hero";
import Layout from "../components/layout";
import CalendarFormSwitcher from "../components/CalendarFormSwitcher";

const PortfolioPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			wpSeoPage(title: { eq: "Portfolio" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}
			allWpPortfolioCard(sort: { order: DESC, fields: dateGmt }) {
				nodes {
					portfolioCardFields {
						description
						colour
						url
						page
						image {
							altText
							link
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
					title
				}
			}
			wpPortfolioPage {
				portfolioPageFields {
					heroSection {
						description
						title
					}
					bookAMeetingSection {
						description
						title
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Portfolio",
				item: {
					url: `${siteUrl}/portfolio`,
					id: `${siteUrl}/portfolio`,
				},
			},
		],
	};
	const {
		wpPortfolioPage: {
			portfolioPageFields: { heroSection, bookAMeetingSection },
		},
	} = data;

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/portfolio`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<Hero
						button1={null}
						button2={null}
						button3={null}
						customTitle
						backgroundImage={data.heroImg.gatsbyImage}
						title={heroSection.title}
						description={heroSection.description}
					/>
				</section>
				<section className="bg-light-grey py-5 py-lg-7">
					<Container>
						<Row className="mb-6 gx-6 gy-7 mb-lg-7 h-100">
							{data.allWpPortfolioCard.nodes.map((port, index) => (
								<Col
									style={{ minHeight: "100%" }}
									className="position-relative "
									xs={12}
									md={6}
									xl={4}
								>
									<div>
										<div
											style={{
												boxShadow: "0px 3px 9px #6565656E",
												maxHeight: "25rem",
												overflow: "hidden",
											}}
										>
											<a
												className="text-decoration-none"
												href={port.portfolioCardFields.url}
											>
												<GatsbyImage
													image={port.portfolioCardFields.image.gatsbyImage}
													alt={port.portfolioCardFields.image.altText}
													className="w-100"
													objectPosition="top center"
												/>
											</a>
										</div>
										<div>
											<a
												className="text-decoration-none"
												href={port.portfolioCardFields.url}
											>
												<h3 className="fs-2 mt-4 mt-lg-5 mb-4 h-100 text-primary ssp-bold text-center">
													{port.title}
												</h3>
											</a>
										</div>
										<p>{port.portfolioCardFields.description}</p>
										<div style={{ height: "3rem" }}></div>
										<div className="text-center position-absolute start-50 bottom-0 translate-middle-x">
											<Button
												className="w-100 w-md-auto fs-5 white-link-yellow py-2 px-4"
												target="_blank"
												rel="noreferrer"
												href={port.portfolioCardFields.url}
												style={{
													backgroundColor: port.portfolioCardFields.colour,
													border: "0px",
													borderRadius: "11px",
												}}
											>
												VISIT LIVE SITE
											</Button>
										</div>
									</div>
								</Col>
							))}
						</Row>
					</Container>
				</section>
				<section className="pb-5 pb-lg-7  bg-light-grey">
					<Container>
						<Row id="form">
							<Col className="text-center">
								{" "}
								<div>{parse(bookAMeetingSection.title)}</div>
								<p>{bookAMeetingSection.description}</p>
							</Col>
						</Row>
						<Row>
							<CalendarFormSwitcher />
						</Row>
					</Container>
				</section>
			</Layout>
		</>
	);
};

export default PortfolioPage;
